
* { 
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  height: 100%;
  height: -webkit-fill-available;
}


#home {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

#home-container {
  background-image: url("components/Pictures/Laila&Horse.JPG ");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 40vw;
}

#logo-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  margin-bottom: 29vh;
}

#home-title {
  color:gold;
  font-size: 2rem;
  font-weight: 300;
} 

#home-links {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.home-languages-container {
  background-color: black;
}

.home-languages-box {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#homeEnglish {
  background-image: url("components/Pictures/Parrot.jpg ");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 50vw;
  position: absolute;
  left: 0px;
  top: 0px;
}

#homeSpanish {
  background-image: url("components/Pictures/Horse.jpg ");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 50vw;
  position: absolute;
  left: 0px;
  top: 0px;
}

#homeItalian {
  background-image: url("components/Pictures/Peacock.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 50vw;
  position: absolute;
  left: 0px;
  top: 0px;
}


#english-links {
  display: flex;
  flex-direction: row; 
  align-items: center; 
  width: 23ch;
  height: 40vh;
  position: fixed;
  right: 0%;
  bottom: 35%;
  background-color: rgb(7, 145, 76);
}


#spanish-links{
  display: flex;
  flex-direction: row; 
  align-items: center; 
  width: 23ch;
  height: 40vh;
  position: fixed;
  right: 0%;
  bottom: 35%;
  background-color: #6c0441;
}

#italian-links{
  display: flex;
  flex-direction: row; 
  align-items: center; 
  width: 23ch;
  height: 40vh;
  position: fixed;
  right: 0%;
  bottom: 35%;
  background-color: rgb(7, 7, 147);
}


.slide-animation {
  animation: slide-left 3s;
}

@keyframes slide-left {
  from {
    right: -20%;
  }

  to {
    right: 0%;
  }
}


#links {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.home-languages-services {
  color: gold;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  padding-left: 10px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5vh;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  background-color: black;
}

.footer-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5vh;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
} 

.footer-pages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5vh;
  font-size: 0.8rem;
  position: fixed;
  bottom: 0;
  background-color: black;
  font-family: 'Lato', sans-serif;
} 

.container-privacy-policy {
  padding: 70px;
  font-family: "Lato", sans-serif;
  background-color: black;
  color: white;
}

.privacy-policy {
  margin-bottom: 100px;
}

#logo-container-home {
  background-image: url("components/logo/PICTO.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}


#logo-container {
  background-image: url("components/logo/PICTO.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: -30px;
  border: 1px solid gold;
}

.logo-container-2 {
  background-image: url("components/logo/PICTO.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
  border: 1px solid gold;
  padding: 30px;
}

#logo-container-3 {
  background-image: url("components/logo/PICTO.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


.person-title {
  display: flex;
  justify-content: center;
}

.title-disclaimer {
  font-weight: 300;
}

.MuiTypography-root{
  font-family: 'Lato', sans-serif;
  font-weight: 350 !important;
} 


#health-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7e2a7e;
}


#communication-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(9, 111, 80);
}

#connection-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4e6ac0;
}

#healings-title {
  color: gold;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px;
}

.form-title {
  color: gold;
  font-weight: 300;
}

.main-title {
  color: gold;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px;
}

.uploads {
  display: flex;
  flex-direction: column;
  margin-top: 15px; 
  width: 40vw;
}

.upload-button {
  margin-top: 10px;
  margin-bottom: 20px;
}

.file-name {
  margin-top: 10px;
  color: #a1a1a1; 
  font-size: 7px;
}

.custom-file-label {
  color: gold;
  padding: 6px;
  cursor: pointer;
  font-weight: 300;
}

.custom-file-input {
  font-weight: 200;
}

.custom-file-button {
  font-weight: 300;
  background-color: #a1a1a1;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.uploaded-files {
  font-size: 0.7rem;
}

.person-fields .custom-width {
  height: 50%;
}

.person-fields {
  font-weight: 200;
}

.person-fields-label {
  font-weight: 200;
}

.signature-box {
  margin-top: 20px;
  font-weight: 200;
}


@media only screen and (max-width: 834px) {
  #home-links {
    display: flex;
    flex-direction: column;
  }

  #logo-container {
    width: 30px;
    height: 30px;
  }

  .uploads {
    width: 80vw;
  }

  .container-privacy-policy {
    padding: 30px;
  }
}

@media only screen and (max-width: 700px) {
  #home {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #home-container {
    height: 50vh;
    width: 100vw;
    position: static;
  }
  
  #logo-links-container {
    width: 100vw;
    height: 30vh;
    position: static;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  #logo-container-home {
    visibility: hidden;
  }

  .home-languages-box {
    display: flex;
    flex-direction: column;
  }

  #logo-container {
    margin-bottom: 7px;
  }

  #homeEnglish {
    height: 50vh;
    width: 100vw;
    top: 50px;
  }

  #homeSpanish {
    height: 50vh;
    width: 100vw;
    top: 50px;
  }

  #homeItalian {
    height: 50vh;
    width: 100vw;
    top: 50px;
  }

  #english-links {
    height: 30vh;
    bottom: 20%;
  }

  #spanish-links{
    height: 30vh;
    bottom: 20%;
  }
  
  #italian-links{
    height: 30vh;
    bottom: 20%;
  }
}


@media only screen and (max-height: 650px) {
  #logo-links-container {
    padding-top: 30px;
  }

  #english-links {
    height: 40vh;
    bottom: 10%;
  }

  #spanish-links{
    height: 40vh;
    bottom: 10%;
  }
  
  #italian-links{
    height: 40vh;
    bottom: 10%;
  }
}

@media only screen and (max-height: 500px) {
  #english-links {
    height: 50vh;
    bottom: 10%;
  }

  #spanish-links{
    height: 50vh;
    bottom: 10%;
  }
  
  #italian-links{
    height: 50vh;
    bottom: 10%;
  }
}


@media only screen and (max-height: 400px) {
  #home-container {
    visibility: hidden;
  }

  #logo-links-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-bottom: 80px;  
    padding-top: 30px;
  }

  #english-links {
    height: 60vh;
    bottom: 8%;
  }

  #spanish-links{
    height: 60vh;
    bottom: 8%;
  }
  
  #italian-links{
    height: 60vh;
    bottom: 8%;
  }

  #logo-container-home {
    visibility: hidden;
  }
}


@media only screen and (max-height: 300px) {
  #english-links {
    height: 80vh;
    bottom: 10%;
  }

  #spanish-links{
    height: 80vh;
    bottom: 10%;
  }
  
  #italian-links{
    height: 80vh;
    bottom: 10%;
  }
}
